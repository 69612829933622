<template>
  <div>
    <vx-card actionable class="cardx" title="Admin Utilization Report">
      <vs-row type="flex">
        <!--Team -->
        <vs-col v-if="!getTeam('CM')" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-select class="selectExample" label="Team" v-model="selected_team">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in teams" />
          </vs-select>
        </vs-col>

        <vs-col v-if="showSubTeam" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-select class="selectExample" label="Sub Team" v-model="selected_sub_team">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in sub_teams" />
          </vs-select>
        </vs-col>
        <!-- <vs-col v-else vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
          <vs-select disabled class="selectExample" label="Team" v-model="selected_team">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in teams" />
          </vs-select>
        </vs-col> -->
        <!--City -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" v-if="selected_team == 'CM'">
          <vs-select class="selectExample" label="City" v-model="selected_city">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in cities" />
          </vs-select>
        </vs-col>
        <!--City classification -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"
          v-if="selected_team == 'CM' && selected_city != ''">
          <vs-select class="selectExample" label="City Classification" v-model="selected_city_classification">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in city_classification" />
          </vs-select>
        </vs-col>
        <!--Course -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="selected_team == 'ExEd'">
          <vs-select class="selectExample" label="Courses" v-model="selected_course">
            <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in courses" />
          </vs-select>
        </vs-col>
        <!--Spocs -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="
            selected_sub_team == 'CM' || selected_sub_team == 'SR' || selected_team == 'Placements'
        ">
          <v-select placeholder="Spocs" style="z-index: 1000; padding-top: 7%;" class="w-full" v-model="selected_spoc"
            :options="spocs" label="Spocs"></v-select>
          <!-- <vs-select
            class="selectExample"
            label="Spocs"
            v-model="selected_spoc"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in spocs"
            />
          </vs-select> -->
        </vs-col>

        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="selected_team == 'SR'"
          style="margin-top: 1.5%">
          <!-- <div v-if="logged_in_team != 'SR'" style="margin-left: 8%">
            <treeselect :multiple="true" :options="sr_spocs_ccf" :value-consists-of="'LEAF_PRIORITY'"
              value-format="object" placeholder="Select SR Spoc" v-model="selected_sr_spoc"
              style="width:100%; position:relative; z-index: 5000;" />
          </div>
          <div v-else style="margin-left: 8%">
            <treeselect disabled :multiple="true" :options="sr_spocs_ccf" :value-consists-of="'LEAF_PRIORITY'"
              value-format="object" placeholder="Select SR Spoc" v-model="selected_sr_spoc"
              style="width:100%; position:relative; z-index: 5000;" />
          </div> -->
          <div v-if="logged_in_team != 'SR'">
            <vs-dropdown vs-custom-content vs-trigger-click class="seleced_spoc">
              <a class="a-icon select-iconns element-text-inside alignment " href.prevent @click="showTreeBox">
                Select SR SPOC
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                <treeselect :multiple="true" :options="sr_spocs_ccf" :value-consists-of="'LEAF_PRIORITY'"
              value-format="object"  v-model="selected_sr_spoc"
              style="width:290px; position:relative; z-index: 5000;" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div v-else>
            <vs-dropdown vs-custom-content vs-trigger-click class="seleced_spoc">
              <a class="a-icon select-iconns element-text-inside alignment py-1" href.prevent @click="showTreeBox">
                Select SR SPOC
                <i class="material-icons">expand_more</i>
              </a>
              <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                <treeselect disabled :multiple="true" :options="sr_spocs_ccf" :value-consists-of="'LEAF_PRIORITY'"
              value-format="object"  v-model="selected_sr_spoc"
              style="width:290px; position:relative; z-index: 5000;" />
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </vs-col>
        <!--Date picker -->
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <div id="datepicker">
            <date-range-picker ref="picker" :opens="'right'" :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
              :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :timePicker24Hour="true"
              :showWeekNumbers="false" :showDropdowns="false" :autoApply="false" v-model="today_date"
              style="width: 240px;margin-top: 16px;"></date-range-picker>
            <!-- <datepicker placeholder="Select Date" v-model="today_date"></datepicker> -->
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" style="margin-top: 1.5%">
          <vs-button color="dark" type="filled" @click="getSpocsData">GO</vs-button>
        </vs-col>
      </vs-row>
      <div class="admin_search_criteria mt-3 mb-3 ml-3" v-if="selected_team == 'SR'">
        <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
        <div style="display: inline-block; width: 100%; margin-left:10px; padding: 10px;">
          <span v-for="chip in searchCriteria" :key="chip.id">
            <vs-chip class="admin_dashboard_criteria_chip" style="color:#000000; background-color: rgb(234, 229, 250);" closable @click="removechip(chip)">
              <b>{{ chip.label }}</b>
            </vs-chip>
          </span>
        </div>
      </div>
    </vx-card>

    <vs-table :data="utilization_data" v-if="loaded" class="mt-3">
      <template slot="thead">
        <vs-th>Spoc</vs-th>
        <vs-th>Spoc Level</vs-th>
        <vs-th>Tenure</vs-th>
        <vs-th>Reporting To</vs-th>
        <vs-th>Utilization(%)</vs-th>
        <!-- <vs-th>Activity(%)</vs-th> -->
        <vs-th>Visitors(%)</vs-th>
        <vs-th>Gmeet(%)</vs-th>
        <vs-th>Enrollments | Complete Payments </vs-th>
        <vs-th>Total</vs-th>
        <vs-th  >Total Calls | Total Unique Calls | Duration | IN | OUT | MIS</vs-th>
        <!-- <vs-th>Total F2F | Face To Face | GMeet | LMS Training | Online Class | Physical Meet</vs-th> -->
        <vs-th v-if='selected_team == "CM" || logged_in_team == "CM"'>Connected Calls | Unique Calls | MWB EWB Unique Calls</vs-th>
         <vs-th v-if='selected_team != "CM" && logged_in_team != "CM"'>Connected Calls | MWB EWB Unique Calls</vs-th>
        <!-- <vs-th>MWB Call Count</vs-th>
              <vs-th>EWB Call Count</vs-th> -->
        <!-- <vs-th>Untracked Incoming Calls | Outgoing Calls</vs-th> -->
        <!-- <vs-th>Missed Calls</vs-th> -->
        <vs-th>MWB Call Count | MWB Connected Calls | Total Unique Calls</vs-th>
        <vs-th>EWB Call Count | EWB Connected Calls | Total Unique Calls</vs-th>

        <vs-th>Long Duration Calls 30-60 Mins | > 60 Mins</vs-th>
        <vs-th>Total NUC | Duration</vs-th>
        <!-- <vs-th v-if='selected_team == "SR"'>Total EWB Calls | Unique Connected Calls | Incentives Approved | Recording Available (enrollments greater than 30days) </vs-th>
        <vs-th v-if='selected_team == "SR"'>Total EWB Calls | Unique Connected Calls | Incentives Approved | Recording Available (enrollments less than or equals to 30days)</vs-th> -->
        <vs-th
          v-if="this.timetoTimestamp(this.today_date.startDate) ===this.timetoTimestamp(this.today_date.endDate)">Attendance</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="item.spoc_name" v-for="item in data">
          <vs-td :data="item.spoc_name">
            <div>
              {{ item.spoc_name }}
              <!-- {{ item.user_tag ? "-" : "" }}
              {{ item.user_tag ? item.user_tag : "" ? item.user_tag : null }} -->
            </div>
          </vs-td>
          <vs-td :data="item.spoc_name">
            <div>
              {{ item.user_tag ? item.user_tag : "" ? item.user_tag : null }}
            </div>
          </vs-td>
          <vs-td :data="item.nhf_since">
            <div>
              {{ item.nhf_since }}
            </div>
          </vs-td>
          <vs-td :data="item.reporting_to">
            <div>{{ item.reporting_to }}</div>
          </vs-td>
          <vs-td :data="item.call_utilization">
            <div>{{ item.call_utilization }}</div>
          </vs-td>

          <!-- <vs-td :data="item.activity_sum">
            <div>{{ item.activity_sum }}%</div>
          </vs-td> -->

          <vs-td :data="item.visitor_utilization">
            <div>
              {{ item.visitor_utilization }} |
              {{ item.visit_engagements.length }}
            </div>
          </vs-td>
          <vs-td :data="item.gmeet_utilization">
            <div>{{ item.gmeet_utilization }} | {{ item.gmeet.length }}</div>
          </vs-td>

          <vs-td>
            <!-- <div>
                    {{ item.enrollments.length }}
                  </div> -->
            <div>
              <a href="#" @click="
                  EmitEvent('utilization-enrollment', [
                    item.actual_enrollments,
                    item.actual_enrollments.length,
                    item.spoc_name,
                  ])
              ">{{ item.actual_enrollments.length }}</a>
              -
              <a href="#" @click="
                  EmitEvent('utilization-enrollment', [
                    item.enrollments_without_pending_payments,
                    item.enrollments_without_pending_payments.length,
                    item.spoc_name,
                  ])
              ">
                {{ item.enrollments_without_pending_payments.length }}</a>
            </div>
          </vs-td>

          <vs-td :data="item.total_utilization">
            <vs-chip :color="getTotalUtilization(item.call_utilization,item.visitor_utilization,item.gmeet_utilization,'color')">{{
              getTotalUtilization(item.call_utilization,item.visitor_utilization,item.gmeet_utilization,'data')
            }}</vs-chip>
          </vs-td>

          <vs-td :data="item.total_calls">
            <div>
              {{ item.total_calls }} | {{ item.total_unique_calls }} | {{ item.total_duration }} | {{ item.incoming_call_count }} | {{ item.outgoing_call_count }} | {{ item.missed_call_count }}
            </div>
          </vs-td>
          <!-- <vs-td :data="item.f2f_count">
            <div>
              <a @click="openActivityPopup('f2f_main',item.f2f_count,item.spoc_id)">{{ item.f2f_count }}</a> | <a @click="openActivityPopup('face_to_face_main',item.face_to_face_count,item.spoc_id)">{{  item.face_to_face_count }}</a> |
               <a @click="openActivityPopup('g_meet_main',item.g_meet_count,item.spoc_id)">{{ item.g_meet_count }}</a> | <a @click="openActivityPopup('lms_training_main',item.lms_training_count,item.spoc_id)">{{ item.lms_training_count }}</a> |
               <a @click="openActivityPopup('online_class_main',item.online_class_count,item.spoc_id)">{{ item.online_class_count }}</a> | <a @click="openActivityPopup('physical_meet_main',item.physical_meet_count,item.spoc_id)">{{ item.physical_meet_count }}</a>
            </div>
          </vs-td> -->

          <vs-td v-if="selected_team == 'CM' || logged_in_team == 'CM'" :data="item.cm_connected_calls">
            <div>
              <a href="#" @click="
                  EmitEvent('utilization-mwb', [
                    item.cm_connected_calls_arr,
                    item.spoc_name,
                    item.cm_connected_calls,
                     'Connected Calls'
                  ])
              ">{{ item.cm_connected_calls }} </a> | {{item.unique_cm_connected_calls}} | {{item.total_mwb_ewb_unique_calls}}
            </div>
          </vs-td>
          <vs-td v-if="selected_team != 'CM' && logged_in_team != 'CM'" :data="item.connected_calls">
            <div>
              <a href="#" @click="
                  EmitEvent('utilization-mwb', [
                    item.connected_calls_arr,
                    item.spoc_name,
                    item.connected_calls,
                     'Connected Calls'
                  ])
              ">{{ item.connected_calls }} </a> | {{item.total_mwb_ewb_unique_calls}}
            </div>
          </vs-td>
          <vs-td :data="item.mwb_call_count">
            <div>
              <a href="#" @click="
                  EmitEvent('utilization-mwb', [
                    item.mwb_arr,
                    item.spoc_name,
                    item.mwb_call_count,
                    'MWB Call Count'
                  ])
              ">{{ item.mwb_call_count }}</a> |
              {{ item.mwb_connected_call_count }}
              <!-- <a href="#" @click="
                  EmitEvent('utilization-mwb', [
                    item.connected_calls_arr,
                    item.spoc_name,
                    item.connected_calls,
                    'MWB Connected Calls'
                  ])
              ">{{ item.connected_calls }}</a>  -->
              | {{ item.total_mwb_unique_calls }}
            </div>
          </vs-td>

          <!-- EWB Call Count|Connected Call|Unique Call -->
          <vs-td :data="item.ewb_call_count">
            <div>
              <a href="#" @click="
                  EmitEvent('utilization-ewb', [
                    item.ewb_arr,
                    item.spoc_name,
                    item.ewb_call_count,
                    'EWB Call Count'
                  ])
              ">{{ item.ewb_call_count }}
              </a> |
              {{ item.ewb_connected_call_count }}
              <!-- <a href="#" @click="
                  EmitEvent('utilization-mwb', [
                    item.ewb_connected_calls_arr,
                    item.spoc_name,
                    item.ewb_connected_calls,
                     'EWB Connected Calls'
                  ])
              ">{{ item.ewb_connected_calls }}</a>  -->
              | {{ item.total_ewb_unique_calls }}
            </div>
          </vs-td>

          <vs-td :data="item.call_30_60">
            <div>
              <a href="#"
                @click="EmitEvent('utilization-long', [item.call_30_60_arr, item.spoc_name, item.call_30_60])">{{
                item.call_30_60
              }}</a>
              |
              <a href="#"
                @click="EmitEvent('utilization-long', [item.call_gt_60_arr, item.spoc_name, item.call_gt_60])">{{
                item.call_gt_60
              }}</a>
            </div>
          </vs-td>

          <vs-td :data="item.nuc_count">
            <div>
              <a href="#" @click="
                  EmitEvent('utilization-nuc', [
                    item.nuc_arr,
                    item.spoc_name,
                    item.nuc_count,
                  ])
              ">{{ item.nuc_count }}</a>
              | {{ item.nuc_duration }}
            </div>
          </vs-td>

          <!-- <vs-td v-if='selected_team == "SR"'>
            <div v-if="'enrollments_greater_than_thirty' in item">
              <a href="#" @click="srIncentiveRaw(item, 'total_ewb_calls', 'enrollments>30days')"> {{ item.enrollments_greater_than_thirty.total_ewb_calls }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'unique_calls', 'enrollments>30days')"> {{ item.enrollments_greater_than_thirty.unique_connected_calls }}</a> |
              <a href="#" @click="srIncentiveRaw(item, 'incentives', 'enrollments>30days')">{{ item.enrollments_greater_than_thirty.incentives_approved }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'call_recording_count', 'enrollments>30days')"> {{ item.enrollments_greater_than_thirty.recording_available }} </a>
            </div>
          </vs-td>
          <vs-td v-if='selected_team == "SR"'>
            <div v-if="'enrollments_less_than_equals_thirty' in item">
              <a href="#" @click="srIncentiveRaw(item, 'total_ewb_calls', 'enrollments<=30days')"> {{ item.enrollments_less_than_equals_thirty.total_ewb_calls }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'unique_calls', 'enrollments<=30days')"> {{ item.enrollments_less_than_equals_thirty.unique_connected_calls }}</a> |
              <a href="#" @click="srIncentiveRaw(item, 'incentives', 'enrollments<=30days')">{{ item.enrollments_less_than_equals_thirty.incentives_approved }} </a> |
              <a href="#" @click="srIncentiveRaw(item, 'call_recording_count', 'enrollments<=30days')"> {{ item.enrollments_less_than_equals_thirty.recording_available }} </a>
            </div>
          </vs-td> -->
          <vs-td
            v-if="
              timetoTimestamp(today_date.startDate) ===
              timetoTimestamp(today_date.endDate)
          ">{{ giveAttendanceTitle(item) }}</vs-td>
          <template slot="expand">
            <vs-row>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <calllog :users="item" :selected_team="selected_team" :logged_in_team="logged_in_team" :activity="sr_activity"></calllog>
              </vs-col>
            </vs-row>
          </template>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup classContent="popup-example" class="popup-width1" :title="`${popup_spoc} | ${popup_titl} `" :active.sync="open_activity_popup">
      <div style="overflow-y:scroll;max-height:600px">
        <vs-table :data="popup_data[0]">
          <template slot="thead">
            <vs-th>
              Spoc Name
            </vs-th>
            <vs-th>
              Talk Time Utilization
            </vs-th>
            <vs-th>
              Date
            </vs-th>
            <vs-th>
              Start Time
            </vs-th>
            <vs-th>
              End Time
            </vs-th>
            <vs-th>
              Duration<br>
              &nbsp;( min )
            </vs-th>
            <vs-th>
              File Name
            </vs-th>
            <vs-th>
              Status
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="data[indextr].spoc_name">
                {{data[indextr].spoc_name}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{data[indextr].talk_time_utilization}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{getDatee(data[indextr].created_at)}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{getTime(data[indextr].start_time)}}
              </vs-td>

              <vs-td :data="data[indextr].id">
                {{getTime(data[indextr].end_time)}}
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{data[indextr].duration}}
              </vs-td>
              <vs-td :data="data[indextr].id">
                <!-- {{data[indextr].file_name}} -->
                <vs-button v-if="data[indextr].file_name != null"
                color="#958cf4"
                icon="remove_red_eye"
                @click="getPreviewSrc(data[indextr].id,data[indextr].created_at)"
                ></vs-button>
              </vs-td>
              <vs-td :data="data[indextr].id">
                {{data[indextr].status}}
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
      </div>
    </vs-popup>
    <vs-popup
      style="z-index: 53001"
      fullscreen
      :active.sync="table_preview_popup"
      :title="`${popup_spoc} | ${popup_titl} | ${popup_date}`"
    >
      <div style="display: flex; justify-content: center">
        <img :src="table_preview_image" width="1000" />
      </div>
    </vs-popup>
  </div>
</template>

<script>
import EventBus from "../components/eventbus.js";
import constants from "../../constants.json";
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import calllog from "../components/pagesComponents/callLogComponent.vue";
import vSelect from "vue-select";
import moment from "moment";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import ApiServices from "../ApiServices.js"
export default {
  components: {
    calllog,
    DateRangePicker,
    "v-select": vSelect,
    Treeselect,
    VSelectTree,
    VTree,
  },
  data() {
    return {
      logged_in_user_id: "",
      logged_in_team: "",
      sr_activity:[],
      table_preview_popup: false,
      table_preview_image:"",
      open_activity_popup: false,
      popup_data:[],
      headers:{'f2f_main':'F2F','face_to_face_main':'Face To Face','g_meet_main':'G Meet','lms_training_main':'LMS Training','online_class_main':'Online Class', "physical_meet_main": 'Physical Meet'},
      popup_titl:"",
      popup_spoc: '',
      popup_date: '',
      spoc_idss:[],
      incentive_data: [],
      total_summary: [],
      day_wise_summary: [],
      selected_sr_spoc: [],
      sr_spocs_ccf: [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      cities: [],
      selected_city: "",
      city_classification: [],
      selected_city_classification: "",
      spoc_with_teams: [],
      selected_team: "",
      selected_sub_team: "",
      showSubTeam: false,
      teams: [],
      sub_teams: ['CM', 'SR'],
      spocs: [],
      selected_spoc: "",
      selected_course: "",
      courses: [],
      utilization_data: [],
      today_date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      loaded: false,
      searchCriteria: [],
      mandays: 0,
      gm_citys: [],
      gm_spoc_ids: [],
      gm_reporting_spocs: '',
      admin_access_spocs : [
        "kabir.chawla@mileseducation.com",
        "ghafir.samad@mileseducation.com",
        "sharon.j@mileseducation.com",
        "athira.m@mileseducation.com",
        "nidhin.ram@mileseducation.com",
        "ghouse.ahmed@mileseducation.com",
        "punith.rao@mileseducation.com",
        "satyam.bhivgade@mileseducation.com",
        "krishna.kanth@mileseducation.com",
        "satram.chaitanya@mileseducation.com",
        "johnadhoc@example.com",
        "apoorva.agarwal@mileseducation.com",
        "pramod.sharma@mileseducation.com",
        "gagan.jot@mileseducation.com",
        "anjum.taj@mileseducation.com",
        "reshma.johnson@mileseducation.com",
        "simran.basha@mileseducation.com",
      ],
      spoc_email_id: '',
      login_spoc_id: "",
    };
  },
  mounted() {
    this.login_spoc_id = localStorage.getItem("spoc_id");
    this.logged_in_team = localStorage.getItem('team');
    this.logged_in_user_id = localStorage.getItem('spoc_id');
    this.getTeams();
    this.spoc_email_id = localStorage.getItem("email_id");
    if (this.admin_access_spocs.includes(this.spoc_email_id)) {
      this.logged_in_team = 'Admin';
    }
    if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
      this.getGmReportingSpocs()
    }
  },
  watch: {
    selected_sr_spoc:function () {
      this.mergeAllSearch();
    },
    selected_team: function (val) {
      this.selected_sub_team = '';
      this.table_data = [];
      this.spocs = [];
      this.sr_spocs_ccf = [
        {
          id: "All",
          label: "All",
          children: [],
        }
      ],
      this.courses = [];
      this.hidetotal = false;
      this.total = {
        missed_calls: [],
        untracked: [],
        to_update: [],
        call_logs_today: [],
        untapped: [],
        delays: [],
        today: [],
        waiting: [],
        net_enquiries: [],
        mhps: [],
        flag: [],
      };
      this.selected_spoc = "";
      if (val == "CM" && this.logged_in_team != 'GM' && localStorage.getItem("sub_team") != 'CM') {
        this.showSubTeam = false;
        // this.cities = [
        //   ...new Set(this.spoc_with_teams.map((item) => item.city)),
        // ];
        // Sorting cities alphabetically
        // this.cities.sort((a, b) => {
        //   if (a < b) return -1;
        //   return a > b ? 1 : 0;
        // });

        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        this.cities = [];
        let citys = [
          ...new Set(this.spoc_with_teams.map((item) => item.city)),
        ];
        citys.forEach(element => {
        if(city_options.includes(element)){
          this.cities.push(element);
        }
        })
      }
      else if (val == "CM" && this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM') {
        this.showSubTeam = false;
        this.cities = this.gm_citys;
        console.log("this.gm_citys", this.gm_citys);
      }
      else if (val == "CM" && this.logged_in_team == 'Admin') {
        // this.cities = [
        //   ...new Set(this.spoc_with_teams.map((item) => item.city)),
        // ];
        this.showSubTeam = false;
        let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
        this.cities = [];
        let citys = [
          ...new Set(this.spoc_with_teams.map((item) => item.city)),
        ];
        citys.forEach(element => {
        if(city_options.includes(element)){
          this.cities.push(element);
        }
      })
      }
      else if (val == "ExEd") {
        this.showSubTeam = false;
        this.courses = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "ExEd" ? item.sub_team : ""
            )
          ),
        ];
        console.log("courses", this.courses);
      }
      else if (val == "SR") {
        this.showSubTeam = false;
        let sr_spocs = [];
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          const element = this.spoc_with_teams[i];
          if (element.team == 'SR' && element.sub_team == 'SR') {
            sr_spocs.push(element)
          }
        }
        console.log("sr_spocs", sr_spocs);
        var unique = sr_spocs
          .map((name) => {
            return {
              count: 1,
              name: name.city,
            };
          })
          .reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
          }, {});
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            var city_obj = {
              id: key,
              label: key,
              children: [],
            };
            this.sr_spocs_ccf[0].children.push(city_obj);
          }
        }
        sr_spocs.forEach((server_spoc) => {
          this.sr_spocs_ccf.forEach((bde) => {

            bde.children.forEach((bdechid) => {

              if (bdechid.label === server_spoc.city) {

                let name_obj = {
                  id: server_spoc.first_name + " " + server_spoc.last_name,
                  label: server_spoc.first_name + " " + server_spoc.last_name,
                };
                bdechid.children.push(name_obj);
                // console.log("unique", bdechid)
              }
            });
          });
        });

        console.log("spocs array dfgfg", this.sr_spocs_ccf);
        // let obj = {
        //   id: "All",
        //   label: "All",
        //   children: [],
        // }
        // this.spocs.push(obj);
        // console.log("gjkfhguiht5ryu",this.spocs);
        // this.spocs = [
        //   ...new Set(
        //     this.spoc_with_teams.map((item) =>
        //       item.team == val && item.sub_team == "SR"
        //         ? item.first_name + " " + item.last_name
        //         : ""
        //     )
        //   ),
        // ];
        // console.log("sr/gm spocs", this.spocs);
      }
      else if (val == "GM") {
        this.showSubTeam = true;
        // let gm_spocs = [];
        // if(this.selected_sub_team == 'CM'){
        //   gm_spocs = [
        //   ...new Set(
        //       this.spoc_with_teams.map((item) =>
        //         item.team == val && item.sub_team == 'CM' ? item.first_name + " " + item.last_name : ""
        //       )
        //     ),
        //   ];
        // }else if(this.selected_sub_team == 'SR'){
        //   gm_spocs = [
        //   ...new Set(
        //       this.spoc_with_teams.map((item) =>
        //         item.team == val && item.sub_team == 'SR'  ? item.first_name + " " + item.last_name : ""
        //       )
        //     ),
        //   ];
        // }
        // this.spocs = gm_spocs.sort();
        // console.log("sr/gm spocs", this.spocs);
      }
      else if (val == "Placements") {
        this.showSubTeam = false;
        console.log(val, "team");
        this.spocs = [];
        this.spocs = [
          ...new Set(
            this.spoc_with_teams.map((item) =>
              item.team == "SR" && item.sub_team == "Alumni SR"
                ? item.first_name + " " + item.last_name
                : ""
            )
          ),
        ];
        console.log("collections spocs", this.spocs);
      }
      this.spocs.splice("", 1);
      this.spocs.unshift("All");
      this.courses.splice("", 1);
    },
    selected_sub_team(val){
      this.spocs = [];
      let gm_spocs = [];
        if(val == 'CM'){
          gm_spocs = [
          ...new Set(
              this.spoc_with_teams.map((item) =>
                item.team == 'GM' && item.sub_team == 'CM' ? item.first_name + " " + item.last_name : ""
              )
            ),
          ];
        }else if(val == 'SR'){
          gm_spocs = [
          ...new Set(
              this.spoc_with_teams.map((item) =>
                item.team == 'GM' && item.sub_team == 'SR'  ? item.first_name + " " + item.last_name : ""
              )
            ),
          ];
        }
        this.spocs = gm_spocs.sort();
        console.log("sr/gm spocs", this.spocs);
        this.spocs.splice("", 1);
        this.spocs.unshift("All");
        this.courses.splice("", 1);
    },
    selected_city: function (val) {
      this.city_classification = [];
      this.selected_city_classification = "";
      let classification = ["All"];
      if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
          console.log("gm_reporting_spocs", this.gm_reporting_spocs);
          for (let i = 0; i < this.gm_reporting_spocs.length; i++) {
          if (
            val == this.gm_reporting_spocs[i].city &&
            this.gm_reporting_spocs[i].active
          ) {
            classification.push(this.gm_reporting_spocs[i].city_classification);
          }
          }
      }else{
      for (let i = 0; i < this.spoc_with_teams.length; i++) {
        if (
          val == this.spoc_with_teams[i].city &&
          this.spoc_with_teams[i].team == "CM"
        ) {
          classification.push(this.spoc_with_teams[i].city_classification);
        }
      }
      }
      console.log("classification", classification);
      // To get distinct values from an array
      this.city_classification = [
        ...new Set(classification.map((item) => item)),
      ];
            if(this.login_spoc_id == 132 ||this.login_spoc_id == 937 || this.login_spoc_id == 39){
             this.city_classification
            }
            else{
            this.city_classification = this.city_classification.filter(item => item !== 'X');
   
           }
    },
  },
  methods: {
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGmReportingSpocs", response);
          if (response.data.spocs.length == 0) {
            this.logged_in_team = 'Admin';
            return false
          } else {
            this.gm_reporting_spocs = response.data.spocs
            let duplicates = []
            response.data.spocs.forEach(element => {
              if (!duplicates.includes(element.city) && element.active) {
                this.gm_citys.push(element.city)
                duplicates.push(element.city)
              }
            })
            return true
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getTeam(providedTeam) {
      if (localStorage.getItem("team") === providedTeam) {
        return true;
      } else {
        return false;
      }
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.selected_sr_spoc.forEach((sort) => {
        if (sort === "All") {
          this.sr_spocs_ccf[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.searchCriteria.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.searchCriteria.push(sort);
        }
      });
    },
    removechip(chip){
        if (this.selected_sr_spoc.indexOf(chip) === -1) {
          this.selected_sr_spoc = [];
          this.sr_spocs_ccf[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.selected_sr_spoc.push(child.label);
              this.searchCriteria.push(child.label);
            }
          });
        } else {
          this.selected_sr_spoc.splice(this.selected_sr_spoc.indexOf(chip), 1);
          this.searchCriteria.splice(this.searchCriteria.indexOf(chip), 1);
        }
    },
    openActivityPopup(val,count,id){
      this.popup_data = []
      if (count>0) {
        this.utilization_data.forEach((slot) => {
          if (slot.spoc_id == id) {
            this.popup_data.push(slot[val])
          }else return
        });
        this.popup_spoc = this.popup_data[0][0].spoc_name
        this.popup_titl = this.headers[val]
        this.open_activity_popup = true
      }
    },
    getTime(date){
      return moment.unix(date).format('h:mm a');

    },
    getDatee(date){
      return moment(date).format('DD MMM YYYY');
    },
    getPreviewSrc(id,date){
      this.table_preview_image = ""
      axios
        .get(`https://miles-sr.2x2.ninja/api/viewSrTeamUtilization?id=${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("imagesrc", response.data);
          this.table_preview_image = response.data;
          this.table_preview_popup = true;
          this.popup_date = this.getDatee(date)
        })
        .catch((error) => {
          this.handleError(error);
        })
      },
    srIncentiveRaw(item, row_category, row_days_classification){
      console.log("item, category",item, row_category, row_days_classification);
      let obj = {
        start_date : moment(this.today_date.startDate).format("YYYY-MM-DD"),
        end_date : moment(this.today_date.endData).format("YYYY-MM-DD"),
        spoc_id : item.spoc_id,
        days_classification : row_days_classification,
        category : row_category
      }
      console.log("srIncentiveRaw",obj);
      ApiServices.srIncentiveRaw(obj)
        .then((response) => {
          console.log("srIncentiveRaw response",response);
                this.EmitEvent('utilization-ewb', [
                 response.data,
                 item.spoc_name,
                 response.data.length,
                 'EWB Call Count'
                ])

        })
        .catch((error) => {
          console.log("srIncentiveRaw error",error);
        });
    },
    getTeams() {
      let url = `${constants.SERVER_API}getAllActiveSpocsForDashbaord`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getAllSpocWithTeams", response);
          this.spoc_with_teams = response.data;
          this.teams = [
            ...new Set(this.spoc_with_teams.map((item) => item.team)),
          ];
          // this.teams.push("CR", "Collections");
          this.teams.push("Placements");
          if(this.logged_in_team== 'SR' && localStorage.getItem('sub_team') == 'SR'){
            this.selected_team = 'SR';
            this.teams = ['SR']
            let obj = {
              id : localStorage.getItem('spoc_name'),
              label : localStorage.getItem('spoc_name')
            }
            this.selected_sr_spoc.push(obj);
            this.getSpocsData();
          }
          else if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
            this.selected_team = 'CM';
            this.teams = ['CM']
          }
          })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async getSpocsData() {
      // if(this.getTeam('CM')){
      //   this.spoc_idss= []
      //   this.spoc_idss= [this.logged_in_user_id]
      //   let params = {
      //       spoc_id: this.logged_in_user_id,
      //       from_date: this.timetoTimestamp(this.today_date.startDate),
      //       to_date: this.timetoTimestamp(this.today_date.endDate),
      //     };
      //   this.getUtilization(params);
      // }
      if (this.dateDiffInDays(this.today_date.startDate, this.today_date.endDate) <= 31) {
      this.$vs.loading();
      let spoc_ids = []
      this.loaded = false;
      if (this.selected_team == "CM") {
        if (this.selected_city_classification != "") {
          if (this.selected_city_classification == "All") {
            spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "CM" &&
                  item.city == this.selected_city ? item.id : ""
                )
              ),
            ];
          } else {
            spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "CM" &&
                  item.city == this.selected_city &&
                  item.city_classification == this.selected_city_classification
                    ? item.id
                    : ""
                )
              ),
            ];
          }
        }
        } else if (this.selected_team == "SR") {
        for (let i = 0; i < this.spoc_with_teams.length; i++) {
          const spoc = this.spoc_with_teams[i];
          for (let j = 0; j < this.selected_sr_spoc.length; j++) {
            const sr_spocs = this.selected_sr_spoc[j].id;
            if (sr_spocs == spoc.full_name && spoc.sub_team == "SR" && spoc.team == "SR") {
              spoc_ids.push(spoc.id)
            }
          }
        }
        this.srIncentiveSummary(spoc_ids);
        // if (this.selected_spoc === "All") {
        //  spoc_ids = [
        //       ...new Set(
        //         this.spoc_with_teams.map((item) =>
        //           item.team == "SR" && item.sub_team == 'SR'  ? item.id : ""
        //         )
        //       ),]
        // } else {
        //  spoc_ids = [
        //       ...new Set(
        //         this.spoc_with_teams.map((item) =>
        //           item.team == "SR" && item.sub_team == 'SR' &&  this.selected_spoc == item.first_name + ' ' + item.last_name ? item.id : ""
        //         )
        //       )]
        // }
        } else if (this.selected_team == "GM") {
        if (this.selected_spoc === "All") {
          if(this.selected_sub_team == 'CM'){
            spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "GM" && item.sub_team == 'CM' ? item.id : ""
                )
              ),]
          }else if(this.selected_sub_team == 'SR'){
            spoc_ids = [
                  ...new Set(
                    this.spoc_with_teams.map((item) =>
                      item.team == "GM" && item.sub_team == 'SR' ? item.id : ""
                    )
              ),]
          }
        } else {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "GM" && this.selected_spoc == item.first_name + ' ' + item.last_name ? item.id : ""
                )
              )]
        }
        } else if (this.selected_team == "Placements") {
        if (this.selected_spoc === "All") {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "SR" && item.sub_team == 'Alumni SR' ? item.id : ""
                )
              ),]
        } else {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "SR" && item.sub_team == 'Alumni SR' && this.selected_spoc == item.first_name + ' ' + item.last_name ? item.id : ""
                )
              )]
        }
        } else if (this.selected_team == "Collections") {
        if (this.selected_spoc === "All") {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "SR" && item.sub_team == 'Collection' ? item.id : ""
                )
              ),]
        } else {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "SR" && item.sub_team == 'Collection' && this.selected_spoc == item.first_name + ' ' + item.last_name ? item.id : ""
                )
              )]
        }
        } else if (this.selected_team == "ExEd") {
        if (this.selected_spoc === "All") {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "ExEd" && item.sub_team == this.selected_course ? item.id : ""
                )
              ),]
        } else {
         spoc_ids = [
              ...new Set(
                this.spoc_with_teams.map((item) =>
                  item.team == "ExEd" && item.sub_team == this.selected_course ? item.id : ""
                )
              )]
        }
      }
        this.spoc_idss= []
        this.spoc_idss = spoc_ids
       console.log("spocsss", spoc_ids);
      // spoc_ids.splice("", 1);

      console.log("spocsss", spoc_ids);
        this.getUtilizationReport(spoc_ids);
      } else {
        this.$vs.notify({
          text: "Date range must be 31 days or less",
          color: "danger",
        });
      }
    },
    srIncentiveSummary(spoc_ids){
      let obj = {
        start_date : moment(this.today_date.startDate).format("YYYY-MM-DD"),
        end_date : moment(this.today_date.endData).format("YYYY-MM-DD"),
        spoc_id : spoc_ids,
      }
      console.log("srIncentiveSummary",obj);
      ApiServices.srIncentiveSummary(obj)
        .then((response) => {
          console.log("srIncentiveSummary response",response);
          if(response.data.length != 0){
            this.incentive_data = response.data;
          }
          // this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("srIncentiveSummary error",error);
        });
    },
    dateDiffInDays(start_date, end_date) {
      let tat_start_date = start_date;
      let tat_end_date = end_date;
      const a = new Date(tat_start_date),
        b = new Date(tat_end_date);
        // days_difference = this.dateDiffInDays(a, b);
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      const days_difference = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      return days_difference;
    },
    async getUtilizationReport(spoc_ids) {
      let spocsCollection = [];
      if(this.getTeam('CM')){
        this.spoc_idss= []
        this.spoc_idss= [this.logged_in_user_id]
        let params = {
            spoc_id: this.logged_in_user_id,
            from_date: this.timetoTimestamp(this.today_date.startDate),
            to_date: this.timetoTimestamp(this.today_date.endDate),
        };
        let response = await this.getUtilization(params);
        spocsCollection.push(response.data.data);
      }
      else if(this.logged_in_team == 'GM' && localStorage.getItem("sub_team") == 'CM'){
          let ids = [];
          console.log("gm_reporting_spocs", this.gm_reporting_spocs);
          for (let i = 0; i < this.gm_reporting_spocs.length; i++) {
              const element = this.gm_reporting_spocs[i];
              if (
                this.selected_city_classification == element.city_classification && element.active
              ){
                ids.push(element.id);
              }else if(this.selected_city_classification == 'All' && element.active){
                ids.push(element.id);
              }
            }
          this.gm_spoc_ids = ids
          for (let i = 0; i < this.gm_spoc_ids.length; i++) {
            if (this.gm_spoc_ids[i] != "") {
                let params = {
                    spoc_id: this.gm_spoc_ids[i],
                    from_date: this.timetoTimestamp(this.today_date.startDate),
                    to_date: this.timetoTimestamp(this.today_date.endDate),
                };
                let response = await this.getUtilization(params);
                spocsCollection.push(response.data.data);
                console.log(response);
            }
          }
      }
      else{
        for (let i = 0; i < spoc_ids.length; i++) {
            if (spoc_ids[i] != "") {
                let params = {
                    spoc_id: spoc_ids[i],
                    from_date: this.timetoTimestamp(this.today_date.startDate),
                    to_date: this.timetoTimestamp(this.today_date.endDate),
                };
                let response = await this.getUtilization(params);
                spocsCollection.push(response.data.data);
                console.log(response);
            }
        }
      }
      console.log("spocsCollection", spocsCollection);
      this.calculateUtilization(spocsCollection);
      this.$vs.loading.close();
      if(spoc_ids.length == 0){
        setTimeout( ()=> {
          this.$vs.loading.close()
        }, 1000);
      }
    },
    getUtilization(params) {
      // let url = `${constants.ADMIN_SERVER_API}getSpocCallLogsForUtilization`;
      let url = `https://admin.milesforce.com/api/getSpocCallLogsForUtilization`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            console.log("getSpocCallLogsForUtilization", response)
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            this.handleError(error);
            reject();
          });
      });
      return promise;
    },
    calculateUtilization(utilization_data) {
      let all_visit_engagments = [];
      for (var i = 0; i < utilization_data.length; i++) {

        var call_duration_sec = 0;
        var gmeet_duration_sec = 0;
        var total_calls = 0;
        var all_call_logs = utilization_data[i].all_call_logs;
        var business_calls_array = [];
        var mwb_call_count = 0;
        var mwb_connected_call_count = 0;
        var ewb_connected_call_count = 0;
        var ewb_call_count = 0;
        var untracked_in_calls_count = 0;
        var untracked_in_calls_duration = 0;
        var untracked_out_calls_count = 0;
        var untracked_out_calls_duration = 0;
        var incoming_call_count = 0;
        var cm_unique_connected_calls = 0;
        var outgoing_call_count = 0;
        var missed_call_count = 0;
        var call_30_60 = 0;
        var call_gt_60 = 0;
        var enrollments = [];
        var total_color = "";
        var nuc_count = 0;
        var nuc_duration = 0;
        var training = utilization_data[i].attendance.filter(function (
          eachatt
        ) {
          return eachatt.type === "training";
        });
        var training_utilization = this.getTrainingUtilization(
          training,
          utilization_data[i].attendance
        );
        var mwb_arr = [];
        var ewb_arr = [];
        var missed_calls_arr = [];
        var nuc_arr = [];
        var average = 0;
        var visitaverage = 0;
        var gmeetaverage = 0;
        let cm_connected_calls = 0;
        let connected_calls = 0;
        let connected_calls_arr = [];
        let cm_connected_calls_arr = [];
        let ewb_connected_calls = 0;
        let ewb_connected_calls_arr = [];
        let ewb_business_calls_array = [];
        let mwb_connected_calls = 0;
        let mwb_connected_calls_arr = [];
        let call_30_60_arr = [];
        let call_gt_60_arr = [];
        let mwb_business_calls_array = [];
        let enrollments_without_pending_payments = [];
        let ewb_levels = ['M7', 'M8', 'M9', 'M10', 'M7-', 'M8-', 'M9-', 'M7-', 'M7X', 'M7#', 'M7+', 'M7-JJ', 'M8+', 'M7D1', 'M7D2'];
        utilization_data[i].actual_enrollments.forEach((enrollment) => {
          let payment_status = this.getPaymentStatus(enrollment);
          if (payment_status === 2) {
            enrollments_without_pending_payments.push(enrollment);
          }
        });
        // console.log(utilization_data[i]);
        if (i === 0) {
          // console.log(utilization_data[i].visit_engagements);
        }
        utilization_data[i].visit_engagements.forEach((visit_engagment) => {
          all_visit_engagments.push(visit_engagment);
        });
        if (
          this.timetoTimestamp(this.today_date.startDate) !==
          this.timetoTimestamp(this.today_date.endDate)
        ) {
          average = this.getCallAverage(
            all_call_logs,
            utilization_data[i].attendance,
            this.mandays, utilization_data[i].spoc_name
          );
          visitaverage = this.getVisitAverage(
            utilization_data[i].visit_engagements,
            // utilization_data[i].attendance
            this.mandays, utilization_data[i].spoc_name
          );
          gmeetaverage = this.getGmeetAverage(
            utilization_data[i].gmeet,
            // utilization_data[i].attendance
            this.mandays, utilization_data[i].spoc_name
          );
        }
        utilization_data[i]["visit_engagements"].forEach((visit_engagement) => {
          if (visit_engagement.visit_status === "Enrollment") {
            // console.log(visit_engagement)
            enrollments.push(visit_engagement);
          }
        });

        for (var y = 0; y < utilization_data[i]["gmeet"].length; y++) {
          gmeet_duration_sec +=
                utilization_data[i]["gmeet"][y].duration_seconds;
        }

        for (var j = 0; j < utilization_data[i]["all_call_logs"].length; j++) {
          utilization_data[i]["all_call_logs"][j].spoc_name =
            utilization_data[i].spoc_name;
          if (
            utilization_data[i]["all_call_logs"][j].contact_type == "B2C" ||
            utilization_data[i]["all_call_logs"][j].contact_type == "B2BIR" ||
            utilization_data[i]["all_call_logs"][j].contact_type == "B2BCR"
          ) {
            if (utilization_data[i]["all_call_logs"][j].updated == 1) {
              business_calls_array.push(
                utilization_data[i]["all_call_logs"][j]
              );
              call_duration_sec +=
                utilization_data[i]["all_call_logs"][j].call_duration;
              total_calls++;
              if (
                utilization_data[i]["all_call_logs"][j].directory === "INCOMING"
              ) {
                incoming_call_count++;
              } else if (
                utilization_data[i]["all_call_logs"][j].directory === "OUTGOING"
              ) {
                outgoing_call_count++;
              }
              if (utilization_data[i]["all_call_logs"][j].call_duration >= 60) {
                connected_calls++;
                connected_calls_arr.push(
                  utilization_data[i]["all_call_logs"][j]
                );
              }
              if (utilization_data[i]["all_call_logs"][j].call_duration >= 60 && utilization_data[i]["all_call_logs"][j].contact_type == "B2C") {
                cm_connected_calls++;
                cm_connected_calls_arr.push(
                  utilization_data[i]["all_call_logs"][j]
                );
              }
              if (ewb_levels.includes(utilization_data[i]["all_call_logs"][j].level)) {
                ewb_call_count++;
                ewb_arr.push(utilization_data[i]["all_call_logs"][j]);
                if (utilization_data[i]["all_call_logs"][j].call_duration >= 60) {
                  ewb_connected_call_count++;
                  ewb_connected_calls_arr.push(
                    utilization_data[i]["all_call_logs"][j]
                  );
                }
                if (utilization_data[i]["all_call_logs"][j].updated == 1) {
                  ewb_business_calls_array.push(
                    utilization_data[i]["all_call_logs"][j]
                  );
                }
              } else {
                mwb_call_count++;
                mwb_arr.push(utilization_data[i]["all_call_logs"][j]);
                if (utilization_data[i]["all_call_logs"][j].call_duration >= 60) {
                  mwb_connected_call_count++;
                  mwb_connected_calls++;
                  mwb_connected_calls_arr.push(
                    utilization_data[i]["all_call_logs"][j]
                  );
                }
                if (utilization_data[i]["all_call_logs"][j].updated == 1) {
                  mwb_business_calls_array.push(
                    utilization_data[i]["all_call_logs"][j]
                  );
                }
              }
              if (
                utilization_data[i]["all_call_logs"][j].call_duration >= 1800 &&
                utilization_data[i]["all_call_logs"][j].call_duration < 3600
              ) {
                call_30_60++;
                call_30_60_arr.push(
                    utilization_data[i]["all_call_logs"][j]);
              } else if (
                utilization_data[i]["all_call_logs"][j].call_duration >= 3600
              ) {
                call_gt_60++;
                call_gt_60_arr.push(
                    utilization_data[i]["all_call_logs"][j]);
              }
            }
            // if (utilization_data[i]["all_call_logs"][j].directory == "MISSED") {
            //   if (utilization_data[i]["all_call_logs"][j].updated === 0) {
            //   missed_call_count++;
            //   missed_calls_arr.push(utilization_data[i]["all_call_logs"][j]);
            //   // console.log(utilization_data[i]["all_call_logs"][j]);
            //   }
            // }
          } else if (
            utilization_data[i]["all_call_logs"][j].contact_type == "untracked"
          ) {
            if (
              utilization_data[i]["all_call_logs"][j]["directory"] == "INCOMING"
            ) {
              untracked_in_calls_count++;
              untracked_in_calls_duration =
                untracked_in_calls_duration +
                utilization_data[i]["all_call_logs"][j]["call_duration"];
            } else if (
              utilization_data[i]["all_call_logs"][j]["directory"] == "OUTGOING"
            ) {
              untracked_out_calls_count++;
              untracked_out_calls_duration =
                untracked_out_calls_duration +
                utilization_data[i]["all_call_logs"][j]["call_duration"];
            }
          } else {
            nuc_count++;
            nuc_arr.push(utilization_data[i]["all_call_logs"][j]);
            nuc_duration =
              nuc_duration +
              utilization_data[i]["all_call_logs"][j].call_duration;
          }
        }
        utilization_data[i].all_call_logs.forEach((call) => {
          if (call.directory === "MISSED") {
            if (
              call.contact_type === "untracked" ||
              call.contact_type === "B2C"
            ) {
              missed_call_count++;
                missed_calls_arr.push(call);
              // if (call.updated === 0) {
              //   missed_call_count++;
              //   missed_calls_arr.push(call);
              // }
            }
          }
        });
        var unique_calls_array = [
          ...new Set(business_calls_array.map((item) => item.person_id)),
        ];
        var ewb_unique_calls_array = [
          ...new Set(ewb_business_calls_array.map((item) => item.person_id)),
        ];
        var mwb_unique_calls_array = [
          ...new Set(mwb_business_calls_array.map((item) => item.person_id)),
        ];
        var call_duration_mins = Math.round(call_duration_sec / 60);
        var call_utilization = 0;
        if (this.selected_team == "SR") {
           call_utilization = Math.round((call_duration_mins * 100) / 300);
        } else {
          call_utilization = Math.round((call_duration_mins * 100) / 180);
        }
        var gmeet_duration_mins = Math.round(gmeet_duration_sec / 60);
        var gmeet_utilization = 0;
        if (this.selected_team == "SR") {
           gmeet_utilization = Math.round((gmeet_duration_mins * 100) / 300);
        } else {
          gmeet_utilization = Math.round((gmeet_duration_mins * 100) / 180);
        }

        var visitor_utilization = 0;
        if (utilization_data[i]["visit_engagements"].length > 0) {
          var first_visit_count = 0;
          var enrollment_visit_count = 0;
          var repeat_visit_count = 0;
          for (
            var k = 0;
            k < utilization_data[i]["visit_engagements"].length;
            k++
          ) {
            if (
              utilization_data[i]["visit_engagements"][k]["visit_status"] ===
              "First"
            ) {
              //12.5%
              first_visit_count++;
            } else if (
              utilization_data[i]["visit_engagements"][k]["visit_status"] ===
              "Enrollment"
            ) {
              //6.25%
              if (
                utilization_data[i]["visit_engagements"][k]["visit_status"] ===
                  "Enrollment" &&
                utilization_data[i]["visit_engagements"][k]["visit_count"] === 1
              ) {
                first_visit_count++;
              } else {
                enrollment_visit_count++;
              }
            } else if (
              utilization_data[i]["visit_engagements"][k]["visit_status"] ===
              "Repeat"
            ) {
              //3.125%
              repeat_visit_count++;
            }
          }
          visitor_utilization =
            first_visit_count * 12.5 +
            enrollment_visit_count * 6.25 +
            repeat_visit_count * 3.125;
        }
        //cm_unique_connected_calls

        utilization_data[i].incoming_call_count = incoming_call_count;
        utilization_data[i].outgoing_call_count = outgoing_call_count;
        utilization_data[i].enrollments = enrollments;
        utilization_data[i].call_duration_sec = call_duration_sec;
        utilization_data[i].training = training;
        utilization_data[i].call_utilization = call_utilization + "%";
        utilization_data[i].gmeet_utilization = gmeet_utilization + "%";
        utilization_data[i].visitor_utilization = visitor_utilization + "%";
        utilization_data[i].training_utilization = training_utilization + "%";
        utilization_data[i].total_utilization =
          Math.round(call_utilization + visitor_utilization);
        if (call_utilization + gmeet_utilization + visitor_utilization >= 100) {
          total_color = "success";
        } else if (
          call_utilization + gmeet_utilization + visitor_utilization >= 80 &&
          call_utilization + gmeet_utilization + visitor_utilization < 100
        ) {
          total_color = "warning";
        } else if (call_utilization + gmeet_utilization + visitor_utilization < 80) {
          total_color = "danger";
        }
        if (
          this.timetoTimestamp(this.today_date.startDate) !==
          this.timetoTimestamp(this.today_date.endDate)
        ) {
          // console.log(average);
          utilization_data[i].call_utilization = average + "%";
          utilization_data[i].visitor_utilization = visitaverage + "%";
          utilization_data[i].gmeet_utilization = gmeetaverage + "%";
          console.log('hhh', visitaverage, gmeetaverage);
          utilization_data[i].total_utilization =
            Math.round(average + visitaverage + gmeetaverage);
          if (average + gmeetaverage + visitaverage >= 100) {
            total_color = "success";
          } else if (
            average + gmeetaverage + visitaverage >= 80 &&
            average + gmeetaverage + visitaverage < 100
          ) {
            total_color = "warning";
          } else if (average + gmeetaverage + visitaverage < 80) {
            total_color = "danger";
          }
        }
        console.log("checkiong utilization_data[i]",utilization_data[i]);
        if(this.selected_team == 'SR'){
          let enrollments_greater_than_thirty = {
            recording_available : 0,
            total_ewb_calls : 0,
            incentives_approved : 0,
            unique_connected_calls : 0
          };
          let enrollments_less_than_equals_thirty = {
            recording_available : 0,
            total_ewb_calls : 0,
            incentives_approved : 0,
            unique_connected_calls : 0
          };
          this.incentive_data.forEach(element => {
            console.log("element.spoc_id",utilization_data[i].spoc_id, element.spoc_id, element);

            if(utilization_data[i].spoc_id == element.spoc_id){
              element.total_summary.forEach(classification => {
                if(classification.days_classification == 'enrollments>30days'){
                  enrollments_greater_than_thirty = {
                    recording_available: classification.recording_available,
                    total_ewb_calls: classification.total_ewb_calls,
                    incentives_approved: classification.incentives_approved,
                    unique_connected_calls: classification.unique_connected_calls,
                  };
                }
                if(classification.days_classification == 'enrollments<=30days'){
                  enrollments_less_than_equals_thirty = {
                    recording_available: classification.recording_available,
                    total_ewb_calls: classification.total_ewb_calls,
                    incentives_approved: classification.incentives_approved,
                    unique_connected_calls: classification.unique_connected_calls,
                  };
                }
              });
              // let enrollments_less_than_thirty = {};
            }
            Object.assign(utilization_data[i], { enrollments_greater_than_thirty : enrollments_greater_than_thirty})
            Object.assign(utilization_data[i], { enrollments_less_than_equals_thirty : enrollments_less_than_equals_thirty})
            Object.assign(utilization_data[i], { day_wise_summary : element.day_wise_summary});
          });
        }
        console.log("connected calls unique calls",utilization_data[i]);
        cm_unique_connected_calls =[
          ...new Set(cm_connected_calls_arr.map((item) => item.last_ten_digits)),
        ];

        utilization_data[i].unique_cm_connected_calls = cm_unique_connected_calls.length;
        utilization_data[i].total_calls = total_calls;
        utilization_data[i].total_unique_calls = unique_calls_array.length;
        utilization_data[i].total_ewb_unique_calls =
          ewb_unique_calls_array.length;
        utilization_data[i].total_mwb_unique_calls =
          mwb_unique_calls_array.length;
          utilization_data[i].total_mwb_ewb_unique_calls =
          mwb_unique_calls_array.length + ewb_unique_calls_array.length;
        utilization_data[i].total_duration =
          this.secondsToHms(call_duration_sec);
        utilization_data[i].total_color = total_color;
        utilization_data[i].mwb_connected_call_count = mwb_connected_call_count;
        utilization_data[i].ewb_connected_call_count = ewb_connected_call_count;
        utilization_data[i].enrollments_without_pending_payments =
          enrollments_without_pending_payments;
        utilization_data[i].mwb_call_count = mwb_call_count;
        utilization_data[i].connected_calls = connected_calls;
        utilization_data[i].cm_connected_calls = cm_connected_calls;
        utilization_data[i].connected_calls_arr = connected_calls_arr;
        utilization_data[i].cm_connected_calls_arr = cm_connected_calls_arr;
        utilization_data[i].ewb_connected_calls = ewb_connected_calls;
        utilization_data[i].ewb_connected_calls_arr = ewb_connected_calls_arr;
        utilization_data[i].mwb_connected_calls = mwb_connected_calls;
        utilization_data[i].mwb_connected_calls_arr = mwb_connected_calls_arr;
        utilization_data[i].mwb_arr = mwb_arr;
        utilization_data[i].ewb_call_count = ewb_call_count;
        utilization_data[i].ewb_arr = ewb_arr;
        utilization_data[i].untracked_in_calls_count = untracked_in_calls_count;
        utilization_data[i].untracked_in_calls_duration = this.secondsToHms(
          untracked_in_calls_duration
        );
        utilization_data[i].untracked_out_calls_count =
          untracked_out_calls_count;
        utilization_data[i].untracked_out_calls_duration = this.secondsToHms(
          untracked_out_calls_duration
        );
        utilization_data[i].missed_call_count = missed_call_count;
        utilization_data[i].missed_calls_arr = missed_calls_arr;
        utilization_data[i].call_30_60 = call_30_60;
        utilization_data[i].call_30_60_arr = call_30_60_arr;
        utilization_data[i].call_gt_60 = call_gt_60;
        utilization_data[i].call_gt_60_arr = call_gt_60_arr;
        utilization_data[i].nuc_count = nuc_count;
        utilization_data[i].nuc_arr = nuc_arr;
        utilization_data[i].nuc_duration = this.secondsToHms(nuc_duration);
      }

      utilization_data.forEach((spoc) => {
        spoc.visit_count = this.getMyVisitCount(
          spoc.spoc_id,
          all_visit_engagments
        );
      });
      this.utilization_data = utilization_data;
      console.log("utilization_data", utilization_data);
      this.loaded = true;
    },
    getTrainingUtilization(TrainingData, attendance) {
      if (
        this.timetoTimestamp(this.today_date.startDate) !==
        this.timetoTimestamp(this.today_date.endDate)
      ) {
        let utilization = this.getTrainingAverage(TrainingData, attendance, 'spoc_name');
        if (isNaN(utilization) !== true) {
          return utilization;
        } else {
          return 0;
        }
      } else {
        if (TrainingData.length !== 0) {
          let utilization = this.getTrainingPercentage(TrainingData);
          return utilization;
        } else {
          return 0;
        }
      }
    },
    getCallAverage(callLogs, attendance, spoc_name) {
      // console.log("called for average");
      let arrpercentage = this.getPercentageByDay(callLogs);
      console.log('arrpercentage', arrpercentage);
      let average = this.calculateAverage(arrpercentage, arrpercentage.length, spoc_name);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getVisitAverage(engagements, attendance, spoc_name) {
      console.log('mandays in visit averrage',engagements, attendance, spoc_name);
      let arr = this.getVisitpercentageperday(engagements);
      let average = this.calculateAverage(arr, attendance, spoc_name);
      // console.log(average);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getGmeetAverage(engagements, attendance, spoc_name) {
      console.log('mandays in gmeet averrage',engagements, attendance, spoc_name);
      let arr = this.getGmeetpercentageperday(engagements);
      let average = this.calculateAverage(arr, arr.length, spoc_name);
      // console.log(average);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getVisitpercentageperday(engagements) {
      let time = [];
      let ByDayData = [];
      engagements.forEach((piece) => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = engagements
        .map((name) => {
          return {
            count: 1,
            name: name.date_for_calc,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        engagements.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getvisitPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getGmeetpercentageperday(engagements) {
      let time = [];
      let ByDayData = [];
      engagements.forEach((piece) => {
        piece.date_for_calc = moment.unix(piece.scheduled_date / 1000).format("DD-MM-YYYY")
        
      });
      var unique = engagements
        .map((name) => {
          return {
            count: 1,
            name: name.date_for_calc,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        engagements.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getGmeetPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getGmeetPercentage(data) {
      console.log("getPercentage data", data);
      let percentage = 0;
      let seconds = 0;

      data.forEach((log) => {
            seconds = seconds + log.duration_seconds;
      });
      let minutes = Math.round(seconds / 60);
      if (this.selected_team == "SR") {
        percentage = Math.round((minutes * 100) / 300);
      } else {
        percentage = Math.round((minutes * 100) / 180);
      }
      return percentage;
    },
    getvisitPercentage(daydata) {
      let percentage = 0;
      daydata.forEach((day) => {
        if (day.visit_status === "First") {
          percentage = percentage + 12.5;
        } else if (day.visit_status === "Enrollment") {
            if(day.visit_count == 1)
            {
                percentage = percentage + 12.5;
            }
            else
            {
                percentage = percentage + 6.25;
            }
        } else if (day.visit_status === "Repeat") {
          percentage = percentage + 3.125;
        }
      });
      return percentage;
    },
    getPercentageByDay(callLogs) {
      let time = [];
      let ByDayData = [];
      callLogs.forEach((log) => {
        log.updated_at_date = moment.unix(log.time / 1000).format("DD-MM-YYYY");
      });
      var unique = callLogs
        .map((name) => {
          return {
            count: 1,
            name: name.updated_at_date,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        callLogs.forEach((log) => {
          if (log.updated_at_date === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });

      console.log('ByDayData', ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let outgoing_call_count = 0;
        //take only the percentage of calls where outgoing calls >=10
        dayData.data.forEach((call_log) => {
            if(call_log.contact_type == 'B2C')
            {
                if(call_log.updated == 1)
                {
                    if(call_log.directory == 'OUTGOING')
                    {
                        outgoing_call_count ++;
                    }
                }
            }
        });
        if(outgoing_call_count >= 10)
        {
            let value = this.getPercentage(dayData.data);
            percentage.push(value);
        }
      });
      console.log('percentage', percentage);
      this.mandays = percentage.length;
      return percentage;
    },
    getTrainingAverage(TrainingData, attendance, spoc_name) {
      let getByday = this.getTrainingDayAverage(TrainingData);
      let average = this.calculateAverage(getByday, attendance, spoc_name);
      return average;
    },
    calculateAverage(arrpercentage, attendance, spoc_name) {
      console.log(attendance);
      console.log('arrpercentage in calculateAverage', arrpercentage, spoc_name)

      // let new_arr = [];
      // for (let j = 0; j < arrpercentage.length; j++) {
      //   const ele = arrpercentage[j];
      //   if(ele >= 10){
      //     new_arr.push(ele)
      //   }
      // }

      //sai krishna
    //   const filtered_percentage = arrpercentage.filter(element => element >= 10);
    //   const filtered_percentage_sum = filtered_percentage.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    //   console.log("calculateAverage..", filtered_percentage.length, filtered_percentage_sum);
      //sai krishna

      // let add = 0;
      // let present = attendance.filter(function(att) {
      //   return att.type === "present";
      // });
      // let present = [];
      // attendance.forEach((day) => {
      //   day.formatted_date = moment
      //     .unix(day.attendance_date)
      //     .format("YYYY MM DD");
      //   // console.log(day.formatted_date);
      //   if (
      //     present.indexOf(day.formatted_date) === -1 &&
      //     day.status === "present"
      //   ) {
      //     present.push(day.formatted_date);
      //   }
      // });
      // arrpercentage.forEach((arr) => {
      //   add = add + arr;
      // });
      // console.log(present);

      //sai krishna
    //   let ave = Math.round(filtered_percentage_sum / filtered_percentage.length);
    const filtered_percentage_sum = arrpercentage.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let ave = Math.round(filtered_percentage_sum / attendance);
      if (!isFinite(ave)) {
        return "NA";
      } else {
        return ave;
      }
      // return Math.round(add / present.length);
    },
    getTrainingDayAverage(TrainingData) {
      let time = [];
      let ByDayData = [];
      TrainingData.forEach((piece) => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = TrainingData.map((name) => {
        return {
          count: 1,
          name: name.date_for_calc,
        };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count;
        return a;
      }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        TrainingData.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getTrainingPercentage(dayData.data);
        percentage.push(value);
      });
      return percentage;
    },
    getTrainingPercentage(data) {
      let minutes = 0;
      data.forEach((datapoint) => {
        minutes = minutes + datapoint.minutes;
      });
      let percentage = (minutes / 60) * 12.5;
      return Math.round(percentage);
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    getMyVisitCount(id, visitArray) {
      let visits_by_id = [];
      visitArray.forEach((visit_engagement) => {
        if (visit_engagement.assigned_spoc_id === id) {
          visits_by_id.push(visit_engagement);
        }
      });
      return visits_by_id.length;
    },
    giveAttendanceTitle(item) {
      console.log("attendance", item.attendance);
      if (item.attendance.length > 0) {
        if (item.attendance[0].status == null) {
          item.attendance[0].status = "";
          return this.formattedAttendance(item.attendance[0].status);
        } else {
          return this.formattedAttendance(item.attendance[0].status);
        }
      } else {
        return "";
      }
    },
    formattedAttendance(word) {
      let replced = word.replace(/_/g, " ");
      let formatted = replced
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return formatted;
    },
    getPaymentStatus(enrollment) {
      let value = 0;
      if (
        enrollment.amount_paid >= enrollment.agreed_cost ||
        enrollment.amount_in_process >= enrollment.agreed_cost ||
        enrollment.amount_in_process + enrollment.amount_paid >=
          enrollment.agreed_cost
      ) {
        value = 2;
      } else {
        value = 1;
      }
      return value;
    },
    getPercentage(callLogs) {
      console.log("getPercentage callLogs", callLogs);
      let percentage = 0;
      let seconds = 0;

      callLogs.forEach((log) => {
        if (log.contact_type === "B2C")
        {
          if (log.updated == 1) {

            seconds = seconds + log.call_duration;
          }
        }
      });
      let minutes = Math.round(seconds / 60);
      if (this.selected_team == "SR") {
        percentage = Math.round((minutes * 100) / 300);
      } else {
        percentage = Math.round((minutes * 100) / 180);
      }
      return percentage;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    // getSRActivity() {

    //   this.sr_activity = [];
    //   let from_date = moment.unix(this.timetoTimestamp(this.today_date.startDate)).format("DD-MM-YYYY");
    //   let to_date = moment.unix(this.timetoTimestamp(this.today_date.endDate)).format("DD-MM-YYYY");
    //   let obj={
    //     'from_date': from_date ,
    //     'to_date':  to_date,
    //     'spoc_ids': this.spoc_idss.toString(),
    //   }
    //   let url = `https://miles-sr.2x2.ninja/api/srTeamUtilization`;
    //     axios
    //       .get(url, {params: obj,headers: {Authorization: `Bearer ${localStorage.userAccessToken}`, },}).then((response) => {
    //         this.sr_activity = response.data
    //         for (let i = 0; i < this.utilization_data.length; i++) {
    //           const element = this.utilization_data[i];
    //           let val = this.sr_activity[element.spoc_id]
    //           let face_to_face =0;
    //           let g_meet = 0;
    //           let lms_training =0;
    //           let online_class =0;
    //           let f2f =0;
    //           let activity_sum =0;
    //           let physical_meet = 0;
    //           for (let j = 0; j < val.length; j++) {
    //             face_to_face += val[j].face_to_face.length
    //             g_meet += val[j].g_meet.length
    //             physical_meet += val[j].physical_meet.length
    //             lms_training += val[j].lms_training.length
    //             online_class += val[j].online_class.length
    //             f2f += val[j].f2f.length
    //             activity_sum += val[j].activity_sum

    //           }


    //           // var date1 = new Date(moment.unix(this.timetoTimestamp(this.today_date.startDate)).format("l"));
    //           // var date2 = new Date(moment.unix(this.timetoTimestamp(this.today_date.endDate)).format("l"));
    //           // const diffTime = Math.abs(date2 - date1);
    //           // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))+1;

    //           const face_to_face_arr = this.sr_activity[element.spoc_id].map(object => object.face_to_face);
    //           const g_meet_arr = this.sr_activity[element.spoc_id].map(object => object.g_meet);
    //           const lms_training_arr = this.sr_activity[element.spoc_id].map(object => object.lms_training);
    //           const online_class_arr = this.sr_activity[element.spoc_id].map(object => object.online_class);
    //           const f2f_arr = this.sr_activity[element.spoc_id].map(object => object.f2f);
    //           const physical_meet_arr = this.sr_activity[element.spoc_id].map(object => object.physical_meet);
    //           if (val.length>0) {
    //             activity_sum = Math.round(activity_sum/val.length)
    //           }else{
    //             activity_sum = 0
    //           }


    //           element.face_to_face_count = face_to_face;
    //           element.g_meet_count = g_meet;
    //           element.lms_training_count =lms_training;
    //           element.online_class_count =online_class;
    //           element.f2f_count = f2f;
    //           element.physical_meet_count = physical_meet;
    //           element.physical_meet = physical_meet_arr;
    //           element.face_to_face = face_to_face_arr;
    //           element.g_meet = g_meet_arr;
    //           element.lms_training = lms_training_arr;
    //           element.online_class = online_class_arr;
    //           element.f2f = f2f_arr;
    //           element.activity_sum = activity_sum;


    //         }
    //         this.loaded = true;
    //         this.getdataa();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.handleError(error);
    //       });

    // },
    getdataa() {
      let array1= ['f2f','face_to_face','g_meet','lms_training','online_class', 'physical_meet']
      let array2= ['f2f_main','face_to_face_main','g_meet_main','lms_training_main','online_class_main', 'physical_meet_main']

      this.utilization_data.forEach((element) => {
        element.f2f_main = []
        element.face_to_face_main = []
        element.physical_meet_main = []
        element.g_meet_main = []
        element.lms_training_main = []
        element.online_class_main = []
        for (let i = 0; i < array1.length; i++) {
          let users =  element[array1[i]].filter(e => e.length);
          users.forEach((user) => {
            for (let j = 0; j < user.length; j++) {
                let k = array1.indexOf(user[j].activity)
                element[array2[k]].push(user[j])
            }
          });
        }
      });
      console.log('utilization data',this.utilization_data);
    },
    getTotalUtilization(a,c,b,data){
      let visit_utilization = Number(c.replace(/%/g, ""));
      let call_utilization = Number(a.replace(/%/g, ""));
      let gmeet_utilization = Number(b.replace(/%/g, ""));
      let val = call_utilization + visit_utilization + gmeet_utilization
      var total_color = ""
      if (Math.round(val) >= 100) {
        total_color = "success";
      } else if (
        Math.round(val) >= 80 &&
        Math.round(val) < 100
      ) {
          total_color = "warning";
      } else if (Math.round(val) < 80) {
        total_color = "danger";
      }
      if (data == 'color') {
        return total_color
      }else return Math.round(val)+'%'
    },
    showTreeBox() {
      return this.sr_spocs_ccf;
    },
  },
};
</script>

<style scoped>
.selectExample {
  width: 90%;
}
.spoc-tree-drop>.vs-con-dropdown {
  /* width: 100%; */
  /* min-height: 32px; */
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.element-text-inside {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.element-text-inside>i {
  color: rgba(0, 0, 0, 0.4);
}

.select-iconns>.material-icons {
  margin-left: auto;
}

.select-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  /* margin-top: 3px; */
  border: 1px solid lightgray;
  /* width: 168%; */
  height: 39px;
  border-radius: 5px;
  display: flex;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.admin_search_criteria {
  width: 99%;
  background: #ffffff;
  min-height: 165px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.Criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.admin_dashboard_criteria_chip {
  border-radius: 5px;
}

.admin_dashboard_criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000 !important;
}
.seleced_spoc {
    width: 15vw;
  }
.no-padding{
  padding:0px;
}
.admin_dashboard_criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000 !important;
}
</style>
